import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ImgControllerService} from '../../../@backend';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AuthenticationService} from '../services/authentication.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {firstValueFrom} from "rxjs";

@Pipe({
  standalone: true,
  name: 'photoSecured'
})
export class PhotoSecuredPipe implements PipeTransform {

  apiUrl: string = environment.baseApiUrl;
  defaultImg: string = '/assets/img/painting_default.jpg';

  constructor(private imgService: ImgControllerService, private http: HttpClient, private authService: AuthenticationService, private sanitizer: DomSanitizer) {
  }

  /**
   *
   * @param value
   * @param type :1 = main image, :0 others
   * @param cdnUrl
   */
  async transform(value: string, type = "1", modifiers: string = "plain/rs:fill:500:500:1/g:no", shared = false): Promise<SafeHtml> {

    let placeholderSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.defaultImg);

    if (!value) {
      return placeholderSafe;
    }

    try {
      const parsed = JSON.parse(value);
      let image = parsed.filter(x => x.type === type) as any; // ARRAY
      if (image.length === 0) { // Nenašli jsme titulní fotku, dáme aspon pracovni
        type = "0";
        image = parsed.filter(x => x.type === type) as any; // ARRAY
      }
      let imageSrc = '' as string;
      let imageOrig = '' as string;

      if (image.length > 0) {
        image = image.shift();// Občas je tam více označených obrázků jako hlavních
        imageSrc = this.apiUrl + '/img/picture/' + encodeURIComponent(modifiers) + "/" + image.filename + "?shared=" + shared;

        const token = this.authService.currentUser.token;
        const headers = new HttpHeaders({Authorization: `Bearer ${token}`});
        const imageBlob = await firstValueFrom(this.http.get(imageSrc, {headers, responseType: 'blob'}));

        if (imageBlob !== null) {
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.onloadend = () => {
              if (typeof reader.result === "string") {
                let result = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result);
                resolve(result);
              }
            }
            reader.readAsDataURL(imageBlob);
          });
        } else {
          return placeholderSafe;
        }
      } else {
        return placeholderSafe;
      }
    } catch (e) {
      return placeholderSafe;
    }
  }
}

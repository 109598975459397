import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  template: `
    <div class="flex flex-col p-2">
      <h1 mat-dialog-title class="text-center">{{title}}</h1>
      <div mat-dialog-content *ngIf="message">
        <div class="m-0 p-0 text-center items-center" [innerHTML]="message"></div>
      </div>
      <div cdkTrapFocus class="flex flex-row place-content-center gap-5 p-1">
        <button mat-flat-button color="warn" (click)="dialogRef.close(true)">{{confirmText}}</button>
        <button mat-flat-button color="primary" (click)="dialogRef.close(false)" cdkFocusInitial>{{cancelText}}</button>
      </div>
    </div>
  `,

})
export class ConfirmDialog {

  public title: string;
  public message: string | null = null;
  public confirmText: string;
  public cancelText: string = 'Zrušit';
  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data){
      this.setData(data.title, data.message, data.confirmText, data?.cancelText);
    }
  }

  setData(title: string, message: string, confirmText: string, cancelText: string = 'Zrušit'){
    this.title = title;
    this.message = message;
    this.confirmText = confirmText;
  }

}

import { Injectable } from '@angular/core';
import {
  RoleWithRelations,
  User,
  UserManagementService,
} from '../../../@backend';
import {firstValueFrom} from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserLocalService {

  public users: User[] = undefined;
  constructor(private userService: UserManagementService) {
    this.loadListOfUsers().catch(e => {
      console.log('Dont have access to users yet')
    });
  }

  async getUsers(): Promise<User[]> {
    if(this.users) {
      return this.users;
    }else {
      return this.loadListOfUsers();
    }

  }

  async getListRoles(): Promise<RoleWithRelations[]> {
    return [];
  }

  async loadListOfUsers() {
    this.users = await firstValueFrom(this.userService.userManagementControllerGetUsersForSelect(true));
    return this.users;
  }


}
